import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PubNubReact from 'pubnub-react';

import { setTrack as setTrackAction } from '../actions/SiteActions';

const propTypes = {
    setTrack: PropTypes.func.isRequired,
};

const defaultProps = {};

function withLive(WrappedComponent, channels = ['soundboard:results']) {
    class WithLive extends PureComponent {
        constructor(props) {
            super(props);

            this.subscribed = false;
            this.registerLive = this.registerLive.bind(this);
            this.unregisterLive = this.unregisterLive.bind(this);

            this.pubnub = new PubNubReact({
                publishKey: 'pub-c-b2b1dce6-73c2-4ed3-8ea7-8cd2308c7a1d',
                subscribeKey: 'sub-c-5d20f938-e09d-11e9-be79-72a3b6cdb824',
            });

            this.pubnub.init(this);
        }

        componentDidMount() {
            // this.registerLive();
        }

        componentWillUnmount() {
            // this.unregisterLive();
        }

        registerLive() {
            const { setTrack } = this.props;

            const methods = {
                'result.saved': data => {
                    console.log('new data', data); // eslint-disable-line
                    setTrack(data);
                },
            };

            const withSession = channels.map(chan => `${chan}`);

            this.pubnub.subscribe({
                channels: withSession,
                withPresence: false,
            });

            withSession.forEach(name => {
                this.pubnub.getMessage(name, msg => {
                    console.log('got live message', name, msg); // eslint-disable-line
                    if (methods[msg.message.event]) {
                        methods[msg.message.event]({
                            ...msg.message.data,
                        });
                    }
                });
            });

            this.pubnub.getStatus(st => {
                console.log('registerLive status', st); // eslint-disable-line
            });
        }

        unregisterLive() {
            if (this.subscribed === true && this.pubnub !== null) {
                this.pubnub.unsubscribeAll();
            }
            this.subscribed = false;
        }

        render() {
            const { setTrack, ...otherProps } = this.props;
            // eslint-disable-next-line
            return <WrappedComponent {...otherProps} />;
        }
    }

    WithLive.propTypes = propTypes;
    WithLive.defaultProps = defaultProps;

    return WithLive;
}

const mapDispatchToProps = dispatch => ({
    setTrack: it => dispatch(setTrackAction(it)),
});

const composedHoc = compose(
    connect(
        null,
        mapDispatchToProps,
    ),
    withLive,
);

export default composedHoc;
