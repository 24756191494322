// import clap from './tr808/clap.mp3';
// import closedHat from './tr808/closed-hat.mp3';
import cowbell from './tr808/cowbell.mp3';
import kick from './tr808/kick.mp3';
import snare from './tr808/snare.mp3';

import dindeNoire from './quebec/dinde_noire.mp3';
// import francoCanadien from './quebec/franco_canadien.mp3';

import erection from './elections/harper-erection.mp3';
import elvis from './elections/elvis-franco.mp3';

import justin from './elections/justin-apologize.wav';
import maxime from './elections/maxime-islam.mp3';
import scheer from './elections/scheer-oleo.mp3';
import singh from './elections/singh-lgbt.wav';

// import voteethnique from './quebec/voteethnique_2.mp3';
import may from './elections/may.mp3';
import blanchet from './elections/blanchet.mp3';
// import takeakayak from './quebec/takeakayak.mp3';
// import faceClaque from './quebec/face-claque.mp3';
// import neverforget from './quebec/neverforget.mp3';

export default {
    1: justin,
    2: scheer,
    3: blanchet,
    4: singh,
    5: may,
    6: maxime,
    7: elvis,
    8: dindeNoire,
    9: erection,
    10: kick,
    11: snare,
    12: cowbell,
};
