import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Tone from 'tone';

import Track from '../partials/Track';
import Button from '../buttons/Button';
import Pause from '../icons/Pause';
import Play from '../icons/Play';

import { useSampler, useSynth } from '../hooks/useTone';

import { setTrack as setTrackAction } from '../../actions/SiteActions';

import styles from '../../../styles/pages/home.scss';

const propTypes = {
    tracks: PropTypes.object.isRequired, // eslint-disable-line
    samples: PropTypes.object.isRequired, // eslint-disable-line
    width: PropTypes.number.isRequired,
};

const defaultProps = {};

const HomePage = ({ tracks, samples, width }) => {
    const steps = width >= 768 ? 8 : 4;
    const stepRef = useRef(1);

    const [showAdvanced, setShowAdvanced] = useState(false);
    const [started, setStarted] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [loop, setLoop] = useState(null);
    const [step, setStep] = useState(1);
    const [pitch, setPitch] = useState('C5');
    const [bpm, setBpm] = useState(120);
    const [fraction, setFraction] = useState('4');

    const { sampler } = useSampler(samples);
    const { synth } = useSynth();

    const onPlayPause = useCallback(() => {
        Tone.context.resume();
        setPlaying(!playing);
    }, [playing]);

    const onClick = useCallback(() => {
        if (!started) {
            setStarted(true);
            setPlaying(true);
        }
    }, [started, setStarted, setPlaying]);

    useEffect(() => {
        if (synth) {
            synth.triggerAttackRelease('C4', '8n');
        }

        if (playing) {
            setStarted(true);
            Tone.Transport.start();
        } else {
            Tone.Transport.stop();
        }
    }, [playing, setStarted]);

    const onPitch = useCallback(() => {
        switch (pitch) {
            case 'A4':
                setPitch('C5');
                break;
            case 'C5':
                setPitch('F5');
                break;
            case 'F5':
                setPitch('G5');
                break;
            case 'G5':
                setPitch('A4');
                break;
            default:
        }
    }, [pitch, setPitch]);

    const onBpm = useCallback(() => {
        switch (bpm) {
            case 90:
                setBpm(110);
                break;
            case 110:
                setBpm(120);
                break;
            case 120:
                setBpm(140);
                break;
            case 140:
                setBpm(160);
                break;
            case 160:
                setBpm(220);
                break;
            case 220:
                setBpm(90);
                break;
            default:
        }
    }, [bpm, setPitch]);

    const onFraction = useCallback(() => {
        switch (fraction) {
            case '2':
                setFraction('4');
                break;
            case '4':
                setFraction('6');
                break;
            case '6':
                setFraction('2');
                break;
            default:
        }
    }, [fraction, setFraction]);

    const onReset = useCallback(() => {
        setStep(1);
        stepRef.current = 0;
    }, [step, setStep]);

    const action = useCallback(() => {
        const cStep = (stepRef.current % steps) + 1;
        // console.log(time, cStep);
        Object.keys(tracks).forEach(track => {
            if (tracks[track][cStep] === true && sampler[track]) {
                // console.log('play', track, cStep);
                sampler[track].triggerAttackRelease(pitch, `0:${fraction}:0`);
            }
        });
        setStep(cStep);
        stepRef.current += 1;
    }, [step, tracks, sampler, pitch, fraction]);

    useEffect(() => {
        if (sampler !== null && loop === null) {
            Tone.Transport.bpm.value = bpm;
            const toneLoop = new Tone.Loop(action, '4n').start(0);
            setLoop(toneLoop);
        } else if (loop) {
            loop.callback = action;
        }
    }, [bpm, sampler, action]);

    useEffect(() => {
        if (loop !== null) {
            Tone.Transport.bpm.rampTo(bpm, 2);
        }
    }, [bpm, loop]);

    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                {Object.keys(tracks).map((track, index) => (
                    <Track
                        key={`track-${track}`}
                        current={step}
                        index={index}
                        track={track}
                        max={steps}
                        onClick={onClick}
                        items={tracks[track]}
                    />
                ))}
            </div>
            <div className={styles.toolbar}>
                {showAdvanced ? (
                    <>
                        <Button
                            className={classNames([
                                styles.button,
                                {
                                    [styles.small]: true,
                                    [styles.active]: playing,
                                    [styles.hideMobile]: true,
                                },
                            ])}
                            onClick={onReset}
                        >
                            {step}
                        </Button>
                        <Button
                            className={classNames([
                                styles.button,
                                {
                                    [styles.small]: true,
                                    [styles.active]: playing,
                                },
                            ])}
                            onClick={onFraction}
                        >
                            {fraction}b
                        </Button>
                        <Button
                            className={classNames([
                                styles.button,
                                {
                                    [styles.small]: true,
                                    [styles.active]: playing,
                                },
                            ])}
                            onClick={onPitch}
                        >
                            {pitch}
                        </Button>
                        <Button
                            className={classNames([
                                styles.button,
                                {
                                    [styles.small]: true,
                                    [styles.active]: playing,
                                },
                            ])}
                            onClick={onBpm}
                        >
                            {bpm}
                        </Button>
                    </>
                ) : (
                    <Button
                        className={classNames([
                            styles.button,
                            {
                                [styles.small]: true,
                                [styles.active]: playing,
                            },
                        ])}
                        onClick={() => setShowAdvanced(true)}
                    >
                        +
                    </Button>
                )}
                <Button
                    className={classNames([
                        styles.button,
                        {
                            [styles.active]: playing,
                        },
                    ])}
                    onClick={onPlayPause}
                >
                    {playing ? <Pause /> : <Play />}
                </Button>
            </div>
            <div className={styles.notification}>Ouvrez et montez le son de votre appareil</div>
        </div>
    );
};

HomePage.propTypes = propTypes;
HomePage.defaultProps = defaultProps;

const WithStateContainer = connect(
    ({
        site: { tracks, samples },
        layout: {
            size: { width },
        },
    }) => ({
        width,
        tracks,
        samples,
    }),
    dispatch => ({
        setTrack: image => dispatch(setTrackAction(image)),
    }),
)(HomePage);

export default WithStateContainer;
