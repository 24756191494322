/**
 * Constants
 */
export const SET_ERRORS = 'site@SET_ERRORS';
export const RESET_REQUEST = 'site@RESET_REQUEST';

export const SET_PLAYING = 'site@SET_PLAYING';
export const SET_TRACK = 'site@SET_TRACK';
export const SET_STEP = 'site@SET_STEP';

/**
 * Actions creator
 */
export const setErrors = payload => ({
    type: SET_ERRORS,
    payload,
});

export const resetErrors = () => ({
    type: SET_ERRORS,
    payload: null,
});

export const resetRequest = () => ({
    type: RESET_REQUEST,
});

export const setPlaying = payload => ({
    type: SET_PLAYING,
    payload,
});

export const setTrack = payload => ({
    type: SET_TRACK,
    payload,
});

export const setStep = payload => ({
    type: SET_STEP,
    payload,
});
