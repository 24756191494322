import heenok from './quebec/heenok.mp3';
import neverforget from './quebec/neverforget.mp3';

import sween1 from '../videos/sween1.mp4';
import sween2 from '../videos/sween2.mp4';

export default {
    line1: {
        heenok1: { sound: heenok, video: sween1 },
        heenok2: { sound: heenok, video: sween2 },
        heenok3: { sound: heenok, video: sween1 },
        heenok4: { sound: heenok, video: sween2 },
    },
    line2: {
        neverforget1: { sound: neverforget, video: sween2 },
        neverforget2: { sound: neverforget, video: sween1 },
        neverforget3: { sound: neverforget, video: sween2 },
        neverforget4: { sound: neverforget, video: sween1 },
    },
    line3: {
        heenok5: { sound: heenok, video: sween1 },
        heenok6: { sound: heenok, video: sween2 },
        heenok7: { sound: heenok, video: sween1 },
        heenok8: { sound: heenok, video: sween2 },
    },
    line4: {
        neverforget5: { sound: neverforget, video: sween2 },
        neverforget6: { sound: neverforget, video: sween1 },
        neverforget7: { sound: neverforget, video: sween2 },
        neverforget8: { sound: neverforget, video: sween1 },
    },
};
