import agenda from './dominic/agenda.wav';
import ecophile from './dominic/ecophile.wav';
import enfant from './dominic/enfant.wav';
import marxisme from './dominic/marxisme.wav';
import montrehallal from './dominic/montrehallal.wav';

export default {
    dominic: {
        1: { sound: agenda },
        2: { sound: ecophile },
        3: { sound: enfant },
        4: { sound: marxisme },
        5: { sound: montrehallal },
    },
};
