import React from 'react';
import PropTypes from 'prop-types';

import Step from './Step';

import heads from '../../../img/chefs';

import styles from '../../../styles/partials/track.scss';

const propTypes = {
    track: PropTypes.string,
    current: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    items: PropTypes.object.isRequired, // eslint-disable-line
    onClick: PropTypes.func.isRequired,
    max: PropTypes.number.isRequired,
};

const defaultProps = {
    track: null,
};

const Track = ({ track, current, index, max, onClick, items }) => {
    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                {Object.keys(items)
                    .slice(0, max)
                    .map((key, idx) => (
                        <Step
                            key={`track-${key}`}
                            track={track}
                            step={key}
                            active={items[key]}
                            image={heads[index + 1]}
                            current={idx + 1 === current}
                            onClickCallback={onClick}
                        />
                    ))}
            </div>
        </div>
    );
};

Track.propTypes = propTypes;
Track.defaultProps = defaultProps;

export default Track;
