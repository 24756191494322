// import clap from './tr808/clap.mp3';
// import closedHat from './tr808/closed-hat.mp3';
import bernier from './bernier.png';
import blancher from './blancher.png';
import dinde from './dinde.png';
import gratton from './gratton.png';
import harper from './harper.png';
import may from './may.png';
import scheer from './scheer.png';
import singh from './sing.png';
import trudeau from './trudeau.png';
import basedrum from './basedrum.png';
import snare from './snare.png';
import cowbell from './cowbell.png';

export default {
    1: trudeau,
    2: scheer,
    3: blancher,
    4: singh,
    5: may,
    6: bernier,
    7: gratton,
    8: dinde,
    9: harper,
    10: basedrum,
    11: snare,
    12: cowbell,
};
