import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import get from 'lodash/get';

import PageMeta from '../partials/PageMeta';
import Step from '../partials/Step';

import { usePlayers } from '../hooks/useTone';
import {
    setPlaying as setPlayingAction,
    setStep as setStepAction,
} from '../../actions/SiteActions';

import styles from '../../../styles/pages/board.scss';

const messages = defineMessages({
    metaTitle: {
        id: 'meta.title',
        defaultMessage: 'Saluut',
    },
});

const propTypes = {
    tracks: PropTypes.object.isRequired, // eslint-disable-line
    board: PropTypes.object.isRequired, // eslint-disable-line
    setStep: PropTypes.func.isRequired,
};

const defaultProps = {};

const BoardPage = ({ tracks, board, setStep }) => {
    const [players, setPlayers] = useState(null);
    const [timeouts, setTimeouts] = useState({});

    const items = Object.keys(board).reduce((r, k) => {
        Object.keys(board[k]).forEach(soundKey => {
            r[soundKey] = board[k][soundKey].sound; // eslint-disable-line no-param-reassign
        });
        return r;
    }, {});

    usePlayers(items, player => {
        setPlayers(player);
    });

    // Smarter cancel but uses internals
    const onClick = useCallback(
        (track, step) => {
            if (players && players.has(step)) {
                const player = players.get(step);
                player.restart();

                if (timeouts[step]) {
                    clearTimeout(timeouts[step]);
                }
                const timeoutId = setTimeout(() => {
                    setStep({ track, step, active: false });
                }, player._buffer.duration * 1000); // eslint-disable-line

                setTimeouts({
                    ...timeouts,
                    [step]: timeoutId,
                });
            }
        },
        [players, timeouts],
    );
    return (
        <div className={styles.container}>
            <PageMeta title={messages.metaTitle} />
            <div className={styles.inner}>
                {Object.keys(board).map(line => (
                    <div key={`line-${line}`} className={styles.line}>
                        {Object.keys(board[line]).map(key => (
                            <Step
                                key={`tile-${key}`}
                                active={get(tracks, `${line}.${key}`, false)}
                                track={line}
                                step={key}
                                video={board[line][key].video}
                                onClickCallback={(track, step) => {
                                    onClick(track, step);
                                }}
                                className={styles.video}
                            />
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

BoardPage.propTypes = propTypes;
BoardPage.defaultProps = defaultProps;

const WithStateContainer = connect(
    ({ site: { board, tracks } }) => ({
        board,
        tracks,
    }),
    dispatch => ({
        setPlaying: image => dispatch(setPlayingAction(image)),
        setStep: image => dispatch(setStepAction(image)),
    }),
)(BoardPage);

// const WithRouterContainer = withRouter(WithStateContainer);

export default WithStateContainer;
