import { useEffect, useState, useRef } from 'react';
import Tone from 'tone';
// import useKey from 'use-key-hook';

// const baseUrl = '/';

export const useSynth = (
    config = {
        oscillator: {
            type: 'triangle8',
        },
        envelope: {
            attack: 2,
            decay: 1,
            sustain: 0.4,
            release: 4,
        },
    },
) => {
    const [synth, setSynth] = useState(null);
    useEffect(() => {
        const toneSynth = new Tone.Synth(config || null).toMaster();
        setSynth(toneSynth);
        return () => {
            synth.disconnect();
        };
    }, []);
    return { synth, setSynth };
};

export const useSequence = (callback, notes, interval = '4n') => {
    const sequence = new Tone.Sequence(
        step => {
            callback(step);
        },
        notes,
        interval,
    ).start(0);
    return { sequence };
};

export const useSampler = (samplesObject = {}) => {
    const sampler = useRef({});
    useEffect(() => {
        Object.keys(samplesObject).forEach(key => {
            const toneSampler = new Tone.Sampler({ C5: samplesObject[key] }, () => {
                sampler.current[key] = toneSampler;
            }).toMaster();
        });
        // return () => {
        //     console.log('motherfucker');
        //     sampler.disconnect();
        // };
    }, []);
    return { sampler: sampler.current };
};

export const usePlayers = (sounds = {}, onLoad = null) => {
    useEffect(() => {
        const tonePlayers = new Tone.Players(sounds, () => {
            if (onLoad !== null) {
                onLoad(tonePlayers);
            }
        }).toMaster();
    }, []);
};
