import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
// import { defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import get from 'lodash/get';
import classNames from 'classnames';
// import Helmet from 'react-helmet';

// import PageMeta from '../partials/PageMeta';
import Button from '../buttons/Button';

import push from '../../../img/dominic/push.png';

import { usePlayers } from '../hooks/useTone';
import {
    setPlaying as setPlayingAction,
    setStep as setStepAction,
} from '../../actions/SiteActions';

import { shuffle } from '../../lib/utils';

import styles from '../../../styles/pages/single.scss';

// const messages = defineMessages({
//     metaTitle: {
//         id: 'meta.dominic',
//         defaultMessage: 'Salu DOminic',
//     },
// });

const propTypes = {
    tracks: PropTypes.object.isRequired, // eslint-disable-line
    sounds: PropTypes.object.isRequired, // eslint-disable-line
    setStep: PropTypes.func.isRequired,
};

const defaultProps = {};

const SinglePage = ({ tracks, sounds, setStep }) => {
    const currentTrack = 'dominic';
    const [active, setActive] = useState(false);
    const [players, setPlayers] = useState(null);
    const [timeouts, setTimeouts] = useState({});
    const [currentStep, setCurrentStep] = useState(1);

    const items = shuffle(Object.keys(sounds)).reduce((r, k) => {
        Object.keys(sounds[k]).forEach(soundKey => {
            r[soundKey] = sounds[k][soundKey].sound; // eslint-disable-line no-param-reassign
        });
        return r;
    }, {});

    usePlayers(items, player => {
        setPlayers(player);
    });

    // Smarter cancel but uses internals
    const onClick = useCallback(() => {
        const step = currentStep;
        if (players && players.has(step)) {
            players.stopAll();

            const player = players.get(step);
            player.restart();
            setActive(true);

            // OnEnd animations
            if (timeouts[step]) {
                clearTimeout(timeouts[step]);
            }
            const timeoutId = setTimeout(() => {
                setStep({ currentTrack, step, active: false });
                setActive(false);
            }, player._buffer.duration * 1000); // eslint-disable-line
            setTimeouts({
                ...timeouts,
                [step]: timeoutId,
            });

            setCurrentStep(
                currentStep < Object.keys(players._players).length ? currentStep + 1 : 1, // eslint-disable-line
            );
        }
    }, [players, timeouts, currentTrack, currentStep, setCurrentStep, setActive]);

    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <Button
                    active={get(tracks, `${currentTrack}.${currentStep}`, false)}
                    onClick={(track, step) => {
                        onClick(track, step);
                    }}
                    className={classNames([
                        styles.button,
                        {
                            [styles.dominic]: true,
                            [styles.active]: active,
                        },
                    ])}
                >
                    <span />
                </Button>
            </div>
            <link rel="preload" as="image" href={push} />
        </div>
    );
};

// <Helmet>
//     <style type="text/css">{`
//         body, html {
//             background: #000;
//         }
//
//         #unmute-button {
//             background-color: transparent !important;
//         }
//     `}</style>
// </Helmet>

SinglePage.propTypes = propTypes;
SinglePage.defaultProps = defaultProps;

const WithStateContainer = connect(
    ({ site: { dominic, tracks } }) => ({
        sounds: dominic,
        tracks,
    }),
    dispatch => ({
        setPlaying: image => dispatch(setPlayingAction(image)),
        setStep: image => dispatch(setStepAction(image)),
    }),
)(SinglePage);

// const WithRouterContainer = withRouter(WithStateContainer);

export default WithStateContainer;
