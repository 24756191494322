import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useFonts } from '@folklore/fonts';

import withLive from '../withLive';

import useWindowSize from '../hooks/useWindowSize';

import { setSize as setSizeAction } from '../../actions/LayoutActions';

import styles from '../../../styles/layouts/main.scss';

const propTypes = {
    children: PropTypes.node.isRequired,
    isPrerender: PropTypes.bool,
    setSize: PropTypes.func.isRequired,
    fonts: PropTypes.shape({
        google: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
        custom: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    }),
};

const defaultProps = {
    isPrerender: false,
    fonts: {
        google: {
            families: ['Montserrat:300,400,600,700'],
        },
        custom: {
            families: [],
        },
    },
};

const MainLayout = ({ fonts, children, setSize, isPrerender }) => {
    const { loaded: fontsLoaded } = useFonts(fonts);
    const size = useWindowSize();

    useEffect(() => {
        setSize(size);
    }, [size.width, size.height]);

    const innerStyle = {
        opacity: fontsLoaded || isPrerender ? 1 : 0,
    };

    return (
        <div className={styles.container}>
            <div className={styles.inner} style={innerStyle}>
                <div className={styles.section}>{children}</div>
            </div>
        </div>
    );
};

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

const WithStateContainer = connect(
    ({ site: { isPrerender } }) => ({
        isPrerender: isPrerender || false,
    }),
    dispatch => ({
        setSize: size => dispatch(setSizeAction(size)),
    }),
)(MainLayout);

const WithLive = withLive(WithStateContainer);

export default WithLive;
