import {
    SET_ERRORS,
    RESET_REQUEST,
    SET_PLAYING,
    SET_TRACK,
    SET_STEP,
} from '../actions/SiteActions';

import samples from '../../sounds/sampler';
import board from '../../sounds/board';
import dominic from '../../sounds/dominic';

const tracks = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
};

const initialState = {
    errors: null,
    statusCode: null,
    messages: null,
    playing: false,
    tracks: {
        1: {
            ...tracks,
        },
        2: {
            ...tracks,
        },
        3: {
            ...tracks,
        },
        4: {
            ...tracks,
        },
        5: {
            ...tracks,
        },
        6: {
            ...tracks,
        },
        7: {
            ...tracks,
        },
        8: {
            ...tracks,
        },
        9: {
            ...tracks,
        },
        10: {
            1: true,
            2: true,
            3: true,
            4: true,
            5: true,
            6: true,
            7: true,
            8: true,
        },
        11: {
            ...tracks,
            4: true,
            8: true,
        },
        12: {
            ...tracks,
            8: true,
        },
    },
    samples,
    board,
    dominic,
};

const SiteReducer = (previousState, action) => {
    let state = previousState || initialState;
    if (typeof state.hydrated === 'undefined' || !state.hydrated) {
        state = {
            ...initialState,
            ...previousState,
            hydrated: true,
        };
    }
    switch (action.type) {
        case SET_ERRORS:
            return {
                ...state,
                errors: action.payload,
            };
        case SET_PLAYING:
            return {
                ...state,
                playing: action.payload,
            };
        case SET_TRACK:
            return {
                ...state,
                tracks: {
                    ...state.tracks,
                    [action.payload.track]: action.payload.track,
                },
            };
        case SET_STEP:
            // console.log(action.payload, {
            //     ...state.tracks,
            //     [action.payload.track]: {
            //         ...(state.tracks[action.payload.track] || null),
            //         [action.payload.step]: action.payload.active,
            //     },
            // });
            return {
                ...state,
                tracks: {
                    ...state.tracks,
                    [action.payload.track]: {
                        ...(state.tracks[action.payload.track] || null),
                        [action.payload.step]: action.payload.active,
                    },
                },
            };
        case RESET_REQUEST:
            return {
                ...state,
                errors: null,
                statusCode: null,
                messages: null,
            };
        default:
            return state;
    }
};

export default SiteReducer;
