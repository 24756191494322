import React, { useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import classNames from 'classnames';

import { setStep as setStepAction } from '../../actions/SiteActions';

import styles from '../../../styles/partials/step.scss';

const propTypes = {
    active: PropTypes.bool,
    current: PropTypes.bool,
    track: PropTypes.string,
    step: PropTypes.string.isRequired,
    video: PropTypes.string,
    image: PropTypes.string,
    setStep: PropTypes.func.isRequired,
    onClickCallback: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    track: null,
    video: null,
    image: null,
    active: false,
    current: false,
    onClickCallback: null,
    className: null,
};

const Step = ({
    active,
    current,
    track,
    step,
    video,
    image,
    setStep,
    onClickCallback,
    className,
}) => {
    const videoRef = useRef(null);
    const onClick = useCallback(() => {
        if (onClickCallback !== null) {
            onClickCallback(track, step, active);
        }
        setStep({ track, step, active: !active });
    });

    useEffect(() => {
        if (videoRef.current !== null) {
            if (active) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    }, [active]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.active]: active,
                    [styles.current]: current,
                    [styles.video]: video !== null,
                    [styles.image]: image !== null,
                    [className]: className !== null,
                },
            ])}
        >
            <button
                className={styles.inner}
                type="button"
                onClick={onClick}
                style={image ? { backgroundImage: `url(${image})` } : null}
            >
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                {video ? <video ref={videoRef} src={video} muted loop playsInline /> : null}
            </button>
        </div>
    );
};

Step.propTypes = propTypes;
Step.defaultProps = defaultProps;

const WithStateContainer = connect(
    null,
    dispatch => ({
        setStep: image => dispatch(setStepAction(image)),
    }),
)(Step);

const WithRouterContainer = withRouter(WithStateContainer);

export default WithRouterContainer;
