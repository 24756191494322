import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const Pause = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="36px"
        height="36px"
        viewBox="0 0 36 36"
        xmlSpace="preserve"
        className={className}
    >
        <path
            fill={color}
            id="pause-icon"
            data-state="playing"
            d="M11,10 L17,10 17,26 11,26 M20,10 L26,10 26,26 20,26"
        />
    </svg>
);

Pause.propTypes = propTypes;
Pause.defaultProps = defaultProps;

export default Pause;
