import { useState, useCallback, useEffect } from 'react';

const getWindowSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
});

const useWindowSize = (opts = {}) => {
    const { onChange = null } = opts;
    const [size, setSize] = useState(getWindowSize());

    const onResize = useCallback(() => {
        const newSize = getWindowSize();
        if (newSize.width !== size.width || newSize.height !== size.height) {
            setSize(newSize);
            if (onChange !== null) {
                onChange(newSize);
            }
        }
    }, [window]);

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [window]);

    return {
        ...size,
    };
};

export default useWindowSize;
