import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const Play = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="36px"
        height="36px"
        viewBox="0 0 36 36"
        xmlSpace="preserve"
        className={className}
    >
        <path
            fill={color}
            id="play-icon"
            data-state="paused"
            d="M11,10 L18,13.74 18,22.28 11,26 M18,13.74 L26,18 26,18 18,22.28"
        />
    </svg>
);

Play.propTypes = propTypes;
Play.defaultProps = defaultProps;

export default Play;
